.range {
  @apply relative z-10 h-10 w-full cursor-pointer appearance-none border-0 bg-transparent focus:shadow-none focus:outline-none focus:ring-0;
}

.range:hover::-webkit-slider-thumb {
  @apply bg-emerald-500;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 3px solid;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);

  @apply cursor-pointer rounded-full border-white bg-emerald-500 drop-shadow;
}

.range::-webkit-slider-thumb:active {
  @apply bg-emerald-600;
}

.transformed {
  background: white;
  transform: perspective(100px) rotateX(1deg) skewY(1deg) rotateY(0.7deg);
  padding: 20px;
  border-radius: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
