@media (min-width: 1024px) {
  .transformed_yellow {
    background: #e9d352;
    transform: perspective(70px) rotateX(1deg) skewY(1deg) rotateY(0.3deg);
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@media (min-width: 1024px) {
  .transformed_olive {
    background: #24deaa;
    transform: perspective(70px) rotateX(1deg) skewY(1deg) rotateY(0.1deg);
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@media (min-width: 1024px) {
  .transformed_azure {
    background: #1ce5ea;
    transform: perspective(70px) rotateX(0deg) skewX(-2deg) rotateY(-0.5deg)
      skewY(-1deg);
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
